//签约详情
<template>
  <div class="signingDetails">
    <backtitle lable="在线签约" />
    <!-- 头部汇总 -->
    <div class="signBackground">
      <sign-header :summaryData="summaryData"></sign-header>
      <div class="contractAmount" v-if="summaryData.status !=='2'">
        合同总文件数 <span class="contractTotal">{{ contractCount }}</span>
      </div>
      <!-- 盖章文件 -->
      <sign-title
        :showCheckbox="false"
        :checked.sync="checked"
        label="盖章文件"
        :amount="amount"
      ></sign-title>
      <base-table
        class="main-page-table"
        :columns="fileColumns"
        :showPage="false"
        :tableAttrs="{
          stripe: false,
          data: fielListData,
          border: true,
          spanMethod: objectSpanMethod
        }"
        :dataSource.sync="fielListData"
        ref="tableData"
        :loadCount="loadCount"
        :webPage="false"
      >
        <template slot="action" slot-scope="scope">
          <icon-button
            @click="showPreview(scope.row)"
            content="预览"
            icon="iconfont iconyulan"
          />
        </template>
      </base-table>
    </div>
    <!-- 列表查看预览文件 -->
    <!-- <preview-dialog
      :previsible.sync="previsible"
      :fileId="fileId"
      :fileType="fileType"
      :dialogType="'list'"
    ></preview-dialog> -->
    <pre-view :fileId="fileId" :isOpen='true' :fileType="fileType" :count="count" />
  </div>
</template>
<script>
import PreView from '@/components/pre-view/pre-view.vue'
import backtitle from '@/pages/business/components/backtitle.vue'
import BaseTable from '@/components/common/table/base-table/base-table'
import IconButton from '@/components/common/button/icon-button/icon-button.vue'
import SignTitle from '../components/sign-title.vue'

import { signApi } from '@/api/businessApi'
import { fileTable } from './utils/details-config'
import SignHeader from '../components/sign-header.vue'
import Storage from '@/utils/storage'
import { filterData } from './utils/utils'
import { getDictLists } from '@/filters/fromDict'

export default {
  name: 'signingDetails',
  components: {
    BaseTable,
    backtitle,
    SignTitle,
    IconButton,

    SignHeader,
    PreView
  },
  data () {
    return {
      contractCount: '', // 合同文件总数
      summaryData: {},
      count: 0,
      fielListData: [],
      loadCount: 0,
      previsible: false,
      fileId: '',
      fileType: '',
      checked: false, // 选择文件
      amount: ''
    }
  },
  mounted () {
    this.summaryData = Storage.getSession('onlineSginData')
    this.contractCount = this.summaryData.contractCount.toString()
    this.amount = this.summaryData.contractCount.toString()
    this.getSignDetail()
  },
  computed: {
    fileColumns () {
      return fileTable(this)
    },
    // 盖章类型
    signerType () {
      return getDictLists('SEAL_TYPE')
    }
  },
  methods: {
    // 获取文件列表
    getSignDetail () {
      signApi.getSignDocList({ taskId: this.summaryData.keyId }).then((res) => {
        this.fielListData = filterData(res.data, 'docName', 'docNameRowSpan')
        console.log(this.fielListData, '22')
      })
    },
    // 表格合并规则
    objectSpanMethod ({ row, column }) {
      if (this.summaryData.suppliersSealType === '0') {
        // 鲜章无合并
        return []
      }
      if (column.property === 'docName') {
        const _row = row.docNameRowSpan
        const _col = _row > 0 ? 1 : 0
        return {
          rowspan: _row,
          colspan: _col
        }
      }
    },
    // 展示预览
    showPreview (data) {
      this.fileId = data.currentFileId ? data.currentFileId : ''
      const fileSuffix = data.fileName ? data.fileName.split('.') : []
      this.fileType = fileSuffix[fileSuffix.length - 1]
      this.count++
    }
  }
}
</script>
<style lang="scss" src='./index.scss' scoped />
